import React from 'react';

import { Modal } from './admin/Modal';
import Bg from './img/background/bg_show.png';
import Logo from './img/logo_show.svg';

export const TopPageShow: React.FC = () => {
  const [signupPopup, setSignupPopup] = React.useState(false);

  return (
    <>
      <div
        className="bg-cover p-4"
        style={{
          backgroundImage: `url(${Bg})`,
          backgroundColor: 'rgba(255,255,255,0.5)',
          backgroundBlendMode: 'lighten',
        }}
      >
        <div
          className="mx-auto bg-cover p-4"
          style={{
            maxWidth: '400px',
          }}
        >
          <div className="mb-4 text-center">
            <div>歌を唄う方のための情報発信サイト</div>
            <img src={Logo} alt="Show verse" className="mx-auto h-12" />
          </div>
          <div className="my-2 block w-full rounded border border-gray-300 bg-white px-4 py-1 text-center shadow">
            <h2 className="my-2 text-xl font-bold">Show verseとは</h2>
            <p>
              プロ・アマ・老若男女を問わず歌を唄う全ての方がご友人・お知り合い、ファンや支援者の方とリモートで簡単に楽しみや喜びを分かち合うことができるサイトです。
            </p>
          </div>
          <div className="my-2 block w-full rounded border border-gray-300 bg-white px-4 py-1 text-center shadow">
            <h2 className="my-2 text-xl font-bold">Show verse ライバー</h2>
            <p>
              Show
              verse（ショーバース）ライバーになると当サイトを使って自らが主宰するイベント情報を発信し、イベントで得られた収益の分配を受け取ることができます。
            </p>
          </div>
          <div className="my-2 block w-full rounded border border-gray-300 bg-white px-4 py-1 text-center shadow">
            <h2 className="my-2 text-xl font-bold">動作環境</h2>
            <p>
              動作ブラウザは、Androidの場合はGoogle Chrome,
              iPhoneの場合はSafariを推奨しています。
              推奨環境以外のブラウザでの動作は保証しておりません。
            </p>
            <p>PCの場合はGoogle Chromeの最新版を推奨しています。</p>
          </div>
          <button
            className="my-2 block w-full rounded border border-orange-500 bg-orange-500 px-4 py-1 text-center text-white shadow"
            onClick={() => {
              setSignupPopup(true);
            }}
          >
            <div className="font-bg">今すぐShow verseライバーになろう</div>
            <div>（クリックして無料登録）</div>
          </button>
          <div className="text-center">
            すでに登録が終わっている方は
            <a href="/accounts/login" className="text-blue-500">
              ログイン
            </a>
          </div>
          {signupPopup && (
            <Modal>
              <div>
                ライバー登録すると、ご利用規約とプライバシーポリシーに同意し
                たことになります。
              </div>
              <a
                href="/accounts/signup"
                className="my-2 block rounded border border-blue-500 bg-blue-500 px-4 py-1 text-center text-white shadow"
              >
                同意して次へ
              </a>
              <button
                className="my-2 block w-full rounded border border-blue-500 px-4 py-1 text-center text-blue-500 shadow"
                onClick={() => {
                  setSignupPopup(false);
                }}
              >
                戻る
              </button>
            </Modal>
          )}
        </div>
      </div>
      <div className="bg-gray-500 py-4 text-center text-sm text-white">
        <div className="py-1">
          <a href="/about_us">運営会社概要</a>
        </div>
        <div className="py-1">
          <a href="/tos">利用規約</a>
        </div>
        <div className="py-1">
          <a href="/tokusho">特定商取引法に基づく表記</a>
        </div>
        <div className="py-1">
          <a href="/privacy">プライバシーポリシー</a>
        </div>
      </div>
    </>
  );
};
